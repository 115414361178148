export default class Connection {

    baseUrl = "https://data.ajff.org";

    jsonApiBase = "/api/";

    constructor() {
        let currentUri = window.location.origin;
        if (currentUri.indexOf('.platformsh.site') > 0) {
            const uriParts = currentUri.split(".");
            uriParts.splice(0, 2);
            this.baseUrl = 'https://' + uriParts.join(".");
        }
        else if (currentUri.indexOf('localhost') > 0) {
            // Not a typical use case.  Would rather just pull from production data.
            // If you need a local backend, here is where to do it.
            this.baseUrl = 'http://ajffdata.lndo.site';
        }
    }

    getBaseUrl = () => {
        return this.baseUrl;
    }

    getJsonApiBase = () => {
        return this.jsonApiBase;
    }

}