import React, { Component } from 'react';
import Button from '../../../ui/Button/Button';
import '../../sidebar/SidebarItem/SidebarItem';

import './BuyButton.css';

export default class BuyButton extends Component {

  render() {
    // Provide defaults that will be overwritten as needed.
    let purchaseUrl = "";
    let buttonClasses = "disabled";
    let text = "UNKNOWN";

    const status = this.props.screening.get('field_sale_status');

    switch (status) {
      case 'pending':
        buttonClasses = 'pending disabled';
        text = 'Coming Soon';
        break;
      case 'pre_sale':
        buttonClasses = 'on-sale';
        text = 'Members and Sponsors Presale';
        purchaseUrl = this.props.screening.get('field_ticket_purchase_url')?.url;
        break;
      case 'on_sale':
        buttonClasses = "on-sale";
        text = "Get Your Tickets";
        purchaseUrl = this.props.screening.get('field_ticket_purchase_url')?.url;
        break;
      case 'date_passed':
        buttonClasses = "date-passed disabled";
        text = "Date Passed";
        break;
      case 'sold_out':
        buttonClasses = "sold-out disabled";
        text = "Sold Out";
        break;
      case 'custom':
        buttonClasses = "custom disabled";
        text = this.props.screening.get('field_custom_button_text');
        break;
      case 'standby':
        buttonClasses = "standby disabled";
        text = "Standby";
        break;
      case 'cancelled':
        buttonClasses = "cancelled grey-color";
        text = 'In-Theater Canceled • Learn More';
        purchaseUrl = '/article/22nd-annual-atlanta-jewish-film-festival-goes-all-virtual';
        break;
      case 'restricted':
        buttonClasses = "on-sale active";
        text = "Restricted";
        purchaseUrl = this.props.screening.get('field_ticket_purchase_url')?.url;
        break;
      default:
        buttonClasses = 'disabled';
        text = 'UNKNOWN';
    }
    buttonClasses += ' buy-button';

    return (<Button buttonClasses={buttonClasses} url={purchaseUrl} text={text} />)
  }
}