import React, { Component } from 'react';
import { IonIcon } from '@ionic/react';
import { chevronDown, close } from 'ionicons/icons';
import SelectorItem from '../SelectorItem/SelectorItem';

import './Selector.css';

export default class Selector extends Component {

    state = {
        expanded: false,
        currentItemId: this.props.currentItemId
    };

    constructor(props) {
        super(props);

        this.wrapperRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);
    }

    componentDidMount() {
        document.addEventListener('mousedown', this.handleClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    componentDidUpdate = () => {
        if(this.props.hasOwnProperty('expanded') && this.state.expanded !== this.props.expanded) {
            this.setState({
                expanded: !this.state.expanded
            });
        }
    }

    render() {
        let itemList = null;
        let icon = null;

        if(this.props.items.length > 1) {
            icon = <IonIcon icon={chevronDown} onClick={this.toggleItemList} />;

            if(this.state.expanded) {
                icon = <IonIcon role="button" icon={close} onClick={this.toggleItemList} />;
                itemList = this.buildItemList(icon);
            }
        }

        return (
            <div ref={this.wrapperRef} className="selector">
                <div className="selector-wrapper">
                    <div className="selector-header" role="button" onClick={this.toggleItemList}>
                        <h4>Pick a Film Title</h4>
                        {icon}
                    </div>
                </div>
                <div className="item-list-container">
                    {itemList}
                </div>
            </div>
        );
    }

    toggleItemList = () => {
        if(this.props.hasOwnProperty('toggleCallback')) {
            this.props.toggleCallback();
        }

        this.setState({
            expanded: !this.state.expanded
        });
    }

    buildItemList = (icon) => {
        let items = [];

        for(let item of this.props.items) {
            items.push(<SelectorItem item={item} />);
        }

        return (
            <div className="item-list">
                {items}
            </div>
        );
    }

    handleClickOutside(event) {
        if(!event.path.find(e => e.localName === 'div' && e.className === 'selector') && this.state.expanded) {
            this.toggleItemList();
        }
    }

}