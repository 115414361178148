import React, { Component } from 'react';
import Paragraph from '../Paragraph/Paragraph';
import ContentGrid from '../../../layout/ContentGrid/ContentGrid';
import Button from '../../../ui/Button/Button';

import './ItemList.css';

export default class ItemList extends Component {

    render = () => {
        let items = [];

        for(const item of this.props.items) {
            if(item.get('type') === 'paragraph--content_item' && item.get('field_content')[0]?.data) {
                items.push(<Paragraph content={item} drupal={this.props.drupal} />);
            } else {
                items.push(<Paragraph content={item} drupal={this.props.drupal} />);
            }
        }

        let colWidth = 3;
        if(this.props.items.length <= 3) {
            colWidth = 4;
        }

        let headline = null;
        if(this.props.headline) {
            headline = (
                <h2 className="flex-grow-1 headline">{this.props.headline}</h2>
            );
        }

        let link = null;
        if(this.props.link) {
            link = (
                <Button url={this.props.link.url} text={this.props.link.title} />
            );
        }

        return (
            <div className="item-list container page-section padded">
                <div className="d-md-flex align-items-center">
                    {headline}
                    <div className="link">
                        {link}
                    </div>
                </div>

                <ContentGrid small={12} medium={6} large={colWidth}>
                    {items}
                </ContentGrid>
            </div>
        )
    }

}