import React, { Component } from 'react';
import FestivalSeriesDetail from './FestivalSeriesDetail';
import SuperShopperPage from './SuperShopperPage';
import Meta from '../../components/atoms/Meta/Meta';

export default class Page extends Component {

    render() {

        let component = <FestivalSeriesDetail {...this.props} />;

        const urlParts = window.location.pathname.replace(/^\/+|\/+$/g, '').split('/');
        if(urlParts[0] === 'super-shopper') {
            component = <SuperShopperPage {...this.props} />;
        }

        const meta = <Meta data={this.props.entity.get('meta')} />

        return (
          <>
            {meta}
            {component}
          </>
        );
    }
}